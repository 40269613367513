import _typeof from "@babel/runtime/helpers/typeof";
import axios from 'axios';
import moment from 'moment';
export function formatDate(date) {
  var dd = date.getUTCDate();
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
}
var nth = function nth(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export function formatPrettyDate(date) {
  var newDate = moment(date, ["YYYY-MM-DD"]).format('dddd MMM. Do');
  return newDate;
  //   console.log('NEW DATE', newDate)
  //   const months = ["January", "February", "March", "April", "May", "June", "July","August", "September", "October", "Nov", "December"]
  //   const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  // //   let dd = newDate.getUTCDate();
  //   let day = date ? moment.day(newDate.getDay()) : ''
  //   let dayDate = date ? newDate.getDate() : ''
  //   console.log('DAY DATE', dayDate)
  //   let dayOrdinal = dayDate ? nth(dayDate) : ''
  //   let mm = months[newDate.getMonth()]
  //   let yyyy = newDate.getFullYear();
  //   return `${day} ${mm}. ${dayDate}${dayOrdinal}`
  //   return `${day}`
}
export var objectToFormData = function objectToFormData(obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File,
      // use recursivity.
      if (_typeof(obj[property]) === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};
export function htmlEncodeInputDisplay(string) {
  if (string == null || jQuery.trim(string) == "") return string;
  string = string.replace(/&lt;/g, "<");
  string = string.replace(/&gt;/g, ">");
  string = string.replace(/&amp;/g, "&");
  string = string.replace(/&nbsp;/g, " ");
  string = string.replace(/\"/g, "&quot;");
  string = string.replace(/&#8221;/g, '"');
  string = string.replace(/&#8220;/g, '"');
  string = string.replace(/&#8211;/g, '-');
  return string;
}
export function fetchEvents(param, event_type) {
  var data = {
    action: 'do_ajax',
    fn: 'get_events',
    dataType: 'json',
    param: param,
    event_type: event_type
  };
  return axios.post(ajaxurl, objectToFormData(data)).then(function (results) {
    return results.data;
  })["catch"](function (error) {
    return error;
  });
}
export function fetchAllEvents() {
  console.log('nonce', eventsNonce);
  var fn = nycjwEventSettings.selected_term ? 'get_events_by_term' : 'get_all_events';
  var data = {
    action: 'event_functions',
    fn: fn,
    dataType: 'json'
    // _ajax_nonce: eventsNonce
  };
  if (nycjwEventSettings.selected_term) {
    data.term = nycjwEventSettings.selected_term;
  }

  // const eventData = axios.post( ajaxurl, objectToFormData(data) )
  //   .then(results => {
  //     return results.data
  //   })
  //   .then(events => {
  //     console.log(console.log('results', events))
  //     return events
  //   })
  //   .catch( error => {
  //     console.log('error', error)
  //     return error 
  //   })
  var eventData = fetch(ajaxurl, {
    method: 'POST',
    body: objectToFormData(data),
    headers: {
      'Accept': 'application/json'
    }
  }).then(function (response) {
    var responseJSON = response.json();
    console.log('response json', responseJSON);
    return responseJSON;
  }).then(function (results) {
    console.log('results', results);
    return results;
  });
  // console.log('event data', eventData)
  return eventData;
}
export function fetchEventContent(slug, intro) {
  var data = {
    action: 'do_ajax',
    fn: 'get_event_by_slug',
    dataType: 'json',
    event: slug,
    intro: intro
  };
  return axios.post(ajaxurl, objectToFormData(data)).then(function (results) {
    if (!results.data) {
      return;
    }
    return results.data;
  })["catch"](function (error) {
    return console.log(error);
  });
}
export function fetchEventContentById(slug, intro, supporting) {
  var data = {
    action: 'do_ajax',
    fn: 'get_event_content_by_id',
    dataType: 'json',
    event: slug,
    intro: intro,
    supporting: supporting
  };
  return axios.post(ajaxurl, objectToFormData(data)).then(function (results) {
    if (!results.data) {
      return;
    }
    return results.data;
  })["catch"](function (error) {
    return console.log(error);
  });
}
export function parseTime(t) {
  var d = '';
  var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
  d += parseInt(time[1]) + (time[3] ? 12 : '00');
  d += ':';
  d += parseInt(time[2]) || '00';
  d += ':00';
  return d;
}
export function beautyTime(t) {
  var express = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/;
  if (express.test(t)) {
    var hour = Number(t.split(":")[0]);
    var minute = t.split(":")[1];
    var type = 'PM';
    if (hour > 11) {
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour == 24) {
        hour = 12;
      }
    } else {
      type = 'AM';
    }
    if (hour == 0) {
      hour = 12;
    }
    return (hour < 10 ? "".concat(hour) : hour) + ":" + minute + " " + type;
  } else {
    return '1:00 AM';
  }
}
export function alphabetically(a, b) {
  // Use toUpperCase() to ignore character casing
  var eventA = a.title.toUpperCase();
  var eventB = b.title.toUpperCase();
  var comparison = 0;
  if (eventA > eventB) {
    comparison = 1;
  } else if (eventA < eventB) {
    comparison = -1;
  }
  return comparison;
}
export function by_start_time(a, b) {
  // Use toUpperCase() to ignore character casing
  var eventA = a.start_time ? moment(a.start_time, ["h:mm A"]).format("HH:mm:ss") : moment('23:59:59', ["h:mm A"]).format("HH:mm:ss");
  var eventB = b.start_time ? moment(b.start_time, ["h:mm A"]).format("HH:mm:ss") : moment('23:59:59', ["h:mm A"]).format("HH:mm:ss");
  if (a.intro || b.intro) return 1;
  var comparison = 0;
  if (eventA > eventB) {
    comparison = 1;
  } else if (eventA < eventB) {
    comparison = -1;
  }
  return comparison;
}
export function by_start_date_time(a, b) {
  var eventAStartDate = a.date ? a.date : a.start_date;
  var eventBStartDate = b.date ? b.date : b.start_date;
  var eventAStartTime = a.dates ? a.dates[0].start_time : a.start_time;
  var eventBStartTime = b.dates ? b.dates[0].start_time : b.start_time;
  // console.log(eventAStartDate)
  if (a.dates) {
    eventAStartDate = a.dates[0].event_date;
    eventAStartTime = a.dates[0].start_time;
  }
  if (b.dates) {
    eventBStartDate = b.dates[0].event_date;
    eventBStartTime = b.dates[0].start_time;
  }
  var eventADateStringStart = "".concat(eventAStartDate, " ").concat(eventAStartTime ? moment(eventAStartTime, ["h:mm A"]).format("HH:mm:ss") : moment(eventAStartDate + 'T06:00:00', ["h:mm A"]).format("HH:mm:ss"));
  var eventBDateStringStart = "".concat(eventBStartDate, " ").concat(eventBStartTime ? moment(eventBStartTime, ["h:mm A"]).format("HH:mm:ss") : moment(eventBStartDate + 'T06:00:00', ["h:mm A"]).format("HH:mm:ss"));
  var eventATimeStart = new Date(eventADateStringStart.replace(/-/g, "/")).getTime();
  var eventBTimeStart = new Date(eventBDateStringStart.replace(/-/g, "/")).getTime();
  var eventAEndDate = a.end_date ? a.end_date : a.date;
  var eventBEndDate = b.end_date ? b.end_date : b.date;
  if (a.dates && a.dates.length > 1) {
    eventAEndDate = a.dates[a.dates.length - 1].event_date;
  }
  if (b.dates && b.dates.length > 1) {
    eventBEndDate = b.dates[b.dates.length - 1].event_date;
  }
  var eventAEndTime = a.dates ? a.dates[0].end_time : a.end_time;
  var eventBEndTime = b.dates ? b.dates[0].end_time : b.end_time;
  var eventADateStringEnd = "".concat(eventAEndDate, " ").concat(eventAEndTime ? moment(eventAEndTime, ["h:mm A"]).format("HH:mm:ss") : false);
  var eventBDateStringEnd = "".concat(eventBEndDate, " ").concat(eventBEndTime ? moment(eventBEndTime, ["h:mm A"]).format("HH:mm:ss") : false);
  // console.log('title:', a.title, 'end time string', eventAEndTime, 'END TIME:', eventADateStringEnd, 'event:', a)
  var eventATimeEnd = new Date(eventADateStringEnd.replace(/-/g, "/")).getTime();
  var eventBTimeEnd = new Date(eventBDateStringEnd.replace(/-/g, "/")).getTime();
  var comparison = 0;
  if (eventAStartDate && eventBStartDate) {
    if (new Date(eventAStartDate).getDay() < new Date(eventBStartDate).getDay()) {
      comparison = -1;
    }
    if (new Date(eventAStartDate).getDay() > new Date(eventBStartDate).getDay()) {
      comparison = 1;
    }
  }

  // if ( a.id === 19948 ) {
  //   console.log('start date:', eventAStartDate, 'compare start date:', comparison)
  // }

  if (comparison === 0 && eventAEndDate && eventBEndDate) {
    if (new Date(eventAEndDate).getDay() < new Date(eventBEndDate).getDay()) {
      comparison = -1;
    }
    if (new Date(eventAEndDate).getDay() > new Date(eventBEndDate).getDay()) {
      comparison = 1;
    }
  }

  // if ( a.id === 19948 ) {
  //   console.log('end date', eventAEndDate, 'comparison end date:', comparison)
  // }

  if (comparison === 0 && eventATimeStart && eventBTimeStart) {
    if (eventATimeStart < eventBTimeStart) {
      comparison = -1;
    }
    if (eventATimeStart > eventBTimeStart) {
      comparison = 1;
    }
  }

  // console.log('title:', a.title, 'start time:', eventATimeStart, 'comparison start time:', comparison)

  if (comparison === 0 && eventATimeEnd && eventBTimeEnd) {
    if (eventATimeEnd < eventBTimeEnd) {
      comparison = -1;
    }
    if (eventATimeEnd > eventBTimeEnd) {
      comparison = 1;
    }
  }

  // console.log('title:', a.title, 'end time:', eventATimeEnd, 'comparison end time:', comparison)

  return comparison;
}
export function by_end_date_time(a, b) {
  var eventAEndDate = a.end_date ? a.end_date : a.date;
  var eventBEndDate = b.end_date ? b.end_date : b.date;
  if (a.dates) {
    eventAEndDate = a.dates[a.dates.length - 1].event_date;
  }
  if (b.dates) {
    eventBEndDate = b.dates[b.dates.length - 1].event_date;
  }
  var comparison = 0;
  if (eventAEndDate > eventBEndDate) {
    comparison = 1;
  } else if (eventAEndDate < eventBEndDate) {
    comparison = -1;
  }
  return comparison;
}
export var moveElementToEndOfArray = function moveElementToEndOfArray(arr, x) {
  // Array is [1, 2, 3, 4, 5] and x = 2
  // final output would be [3, 4, 5, 1, 2]
  var eventToMove = arr[x];
  var newArray = arr.filter(function (item, index) {
    return index !== x;
  });

  // After this loop array will 
  // be [1, 2, 3, 4, 5, 1, 2]
  newArray.push(eventToMove);

  // Splice method will remove first
  // x = 2 elements from the array
  // so array will be [3, 4, 5, 1, 2]

  return newArray;
};
export var moveElementToStartOfArray = function moveElementToStartOfArray(arr, x) {
  // Array is [1, 2, 3, 4, 5] and x = 2
  // final output would be [3, 4, 5, 1, 2]
  var eventToMove = arr[x];
  var newArray = arr.filter(function (item, index) {
    return index !== x;
  });

  // After this loop array will 
  // be [1, 2, 3, 4, 5, 1, 2]
  newArray.unshift(eventToMove);

  // Splice method will remove first
  // x = 2 elements from the array
  // so array will be [3, 4, 5, 1, 2]

  return newArray;
};